<template>
  <v-snackbar
    v-model="toggle"
    app
    bottom
    :timeout="toast.timeout"
    color="grey darken-3"
    content-class="mx-0"
  >
    {{ toast.text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        v-if="toast.button.toggle"
        @click="trigger(toast.button.action)"
      >
        {{ toast.button.text }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<style scoped>
</style>

<script>
  import {
    sync,
  } from 'vuex-pathify'

  export default {
    data: () => ({
      toggle: false,
    }),
    computed: {
      toast: sync('app/toast'),
    },
    watch: {
      toggle (b) {
        console.log('toast', b);
        if (!b) this.hide();
      },
      toast: {
        deep: true,
        immediate: true,
        handler (toast) {
          console.log('toast', toast);
          if (this.toggle!==toast.toggle) this.toggle = toast.toggle;
        }
      }
    },
    methods: {
      hide () {
        this.$set(this, 'toast', {
          toggle: false,
          text: '',
          button: {
            toggle: false,
            texto: 'Ok',
            action: false
          },
          color: ''
        });
      },
      trigger (action) {
        if (action===false) {
          this.hide();
        }else{
          action();
        }
      }
    }
  }
</script>